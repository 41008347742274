body {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'), url(./fonts/Montserrat-Light.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Medium'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-display: swap;
}

